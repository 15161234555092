import * as types from '../constants/core';

export function showHideAlert(data) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_HIDE_ALERT,
      payload: data,
    });
  };
}

export const onStartUpdating = () => (dispatch) => {
  dispatch({
    type: types.SET_ISUPDATING_MODE,
  });
};

export const onCancelUpdating = () => (dispatch) => {
  dispatch({
    type: types.CANCEL_UPDATING_MODE,
  });
};
