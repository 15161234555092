type ArrayPrimitiveValueType = string | number | boolean | null | undefined;

export const isPrimitive = (val: any): val is ArrayPrimitiveValueType => val !== Object(val);

type GetUniqueValuesType = <ArrayType>({ array, key }: { array: ArrayType[], key?: string }) => ArrayType[];
export const getUniqueValues: GetUniqueValuesType = <Type>({ array, key = '' }) => {
  const arrayForUnique = array.map(item => {
    const isPrimitiveValue = isPrimitive(item);
    const keyValue = key && !isPrimitiveValue ? item[key] : item;
    return [keyValue, item];
  });

  return [...new Map(arrayForUnique).values()] as Type[];
};

type GroupByKeyReturnType<ArrayItemType> = {
  [key in string]: ArrayItemType[]
};

export const groupByKey = <ArrayItemType>(
  array: ArrayItemType[],
  groupKey: string,
  defaultValue: string = `NO_${groupKey}`,
): GroupByKeyReturnType<ArrayItemType> => (
    array.reduce<GroupByKeyReturnType<ArrayItemType>>((grouped, item) => {
      const key = String(item[groupKey] ?? defaultValue);

      return ({
        ...grouped,
        [key]: [...(grouped[key] ?? []), item],
      });
    }, {} as GroupByKeyReturnType<ArrayItemType>)
  );

type GroupByKeyInArrayItem<ArrayItemType> = {
  id: (string | number),
  items: ArrayItemType[]
};
type GroupByKeyInArrayReturnType<ArrayItemType> = GroupByKeyInArrayItem<ArrayItemType>[];

export const groupByKeyInArray = <ArrayItemType>(
  array: ArrayItemType[],
  groupKey: string,
  defaultValue: string = `NO_${groupKey}`,
): GroupByKeyInArrayReturnType<ArrayItemType> => {
  const grouped = groupByKey(array, groupKey, defaultValue);
  return Object.entries(grouped).map(([key, value]) => ({ id: key, items: value }));
};
