import { USER_ROLES } from './userRoles';

const FREELANCER_ROLES = {
  WRITER: USER_ROLES.WRITER,
  DESIGNER: USER_ROLES.DESIGNER,
  EXTERNAL: 'EXTERNAL',
};

export {
  FREELANCER_ROLES,
};
