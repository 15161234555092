import * as types from '../constants';

const initialState = [];

export default function userDepartments(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_DEPARTMENTS:
      return action.payload;

    default:
      return state;
  }
}
