import ReactDOM from 'react-dom';
import { ReactNode, useEffect, useState } from 'react';

type PortalPropsType = {
  children: ReactNode,
  className?: string,
};

const Portal = ({ children, className = '' }: PortalPropsType) => {
  const [root, setRoot] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const htmlElement = document.createElement('div');
    document.body.appendChild(htmlElement);
    className && htmlElement.classList.add(...(className ?? '').split(' '));
    setRoot(htmlElement);
    return function close() {
      document.body.removeChild(htmlElement);
    };
  }, []);

  return (
    root && ReactDOM.createPortal(children, root)
  );
};

export default Portal;
