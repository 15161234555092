import * as types from '../constants/client-financial-overview-new';

const initialState = {
  isLoading: true, // just for GET_CLIENT_FILTERS_DATA_SUCCESS,
  financialStatus: [],
  department: [],

  projects: {
    data: [],
    current_page: null,
    per_page: 10,
    total: null,
    last_page: null,
  },
  allLists: [],
  graphicsData: {},
};

export default function clientFinancialOverviewNew(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLIENT_FILTERS_DATA_SUCCESS: {
      const { payload: { department, financialStatus } } = action;

      return {
        ...state, department, financialStatus, isLoading: false,
      };
    }
    case types.GET_CLIENT_FINANCIALS_TOTALS_SUCCESS: {
      return {
        ...state,
        graphicsData: action.payload,
      };
    }
    case types.GET_CLIENT_FINANCIALS_LIST_SUCCESS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case types.GET_CLIENT_FINANCIALS_ALL_LIST_SUCCESS: {
      return {
        ...state,
        allLists: action.payload,
      };
    }
    default:
      return state;
  }
}
