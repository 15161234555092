import React from 'react';
import { connect } from 'react-redux';

import ApproveIconSVG from '../../../../assets/svg/check.svg';

const Alert = ({ alert }) => (
  alert.isVisible ? (
    <div className='alert-container'>
      <div className='alert'>
        <ApproveIconSVG className='alert-icon' />
        <div className='alert-title'>
          {alert.message}
        </div>
      </div>
    </div>
  ) : null
);

const mapStateToProps = state => ({
  alert: state.core.alert,
});

export default connect(mapStateToProps, null)(Alert);
