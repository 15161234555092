import * as types from '../constants/plan-history';

const initialState = {
  client: {
    data: null,
    isLoading: false,
  },
  freelancer: {
    data: null,
    isLoading: false,
  },
};

const planHistory = (state = initialState, action) => {
  switch (action.type) {
    case types.HISTORY_PLAN_CLIENT_LOADING_START:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: true,
        },
      };
    case types.HISTORY_PLAN_CLIENT_LOADING_END:
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
        },
      };
    case types.GET_HISTORY_PLAN_CLIENT_SUCCESS:
      return {
        ...state,
        client: {
          data: action.payload,
          isLoading: false,
        },
      };
    case types.GET_HISTORY_PLAN_CLIENT_FAIL:
      return {
        ...state,
        client: {
          data: null,
          isLoading: false,
        },
      };
    case types.HISTORY_PLAN_FREELANCER_LOADING_START:
      return {
        ...state,
        freelancer: {
          ...state.freelancer,
          isLoading: true,
        },
      };
    case types.HISTORY_PLAN_FREELANCER_LOADING_END:
      return {
        ...state,
        freelancer: {
          ...state.freelancer,
          isLoading: false,
        },
      };
    case types.GET_HISTORY_PLAN_FREELANCER_SUCCESS:
      return {
        ...state,
        freelancer: {
          data: action.payload,
          isLoading: false,
        },
      };
    case types.GET_HISTORY_PLAN_FREELANCER_FAIL:
      return {
        ...state,
        freelancer: {
          data: null,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default planHistory;
