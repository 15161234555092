import { DropdownType, Translation } from '../../types';

export enum ServiceTypeId {
  CONTENT_CREATION = 1,
  REWRITING = 4,
  EDITING = 2,
  LOCALISATION = 3,
  AUDIO_TRANSCRIPTION = 6,
  DESIGN = 5,
  // TODO
  PROFESSIONAL_SERVICES = 8,
  // new service
  TRANSLATION = 9,
}

// For now we cannot select TRANSLATION service
// only in localisation case on separate step we can select it fpr each language pair separately
export type SelectableServiceTypeId = Exclude<ServiceTypeId, ServiceTypeId.TRANSLATION>;

export const getCopyServices = (t: Translation): DropdownType[] => [
  { id: 1, name: t('content_creation') },
  { id: 2, name: t('editing') },
  { id: 3, name: t('localisation') },
  { id: 4, name: t('re_writing') },
  { id: 6, name: t('audio_transcription') },
  { id: ServiceTypeId.TRANSLATION, name: t('translation') },
];
