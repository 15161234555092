import { FunctionComponent } from 'react';
import styles from './SetUpAutomaticBillingModal.module.scss';
import { useAppTranslate } from '../../hooks';
import LinkIconSVG from '../../assets/svg/link.svg';
import WalletIconSVG from '../../assets/svg/wallet.svg';

const SetUpAutomaticBillingModal: FunctionComponent<{ getStartedClick : () => void }> = ({ getStartedClick }) => {
  const t = useAppTranslate();

  const listArray: { id: number, text: string }[] = [
    { id: 1, text: t('We automatically generate an invoice at the end of the month.') },
    { id: 2, text: t('You only have to check and approve the invoice and we take care of the rest.') },
    { id: 3, text: t('You’ll receive your payment, always on time.') },
  ];

  return (
    <div className={styles.setUpAutomaticBilling}>
      <header>
        <div className={styles.headerIcon}>
          <WalletIconSVG className={styles.icon} />
        </div>
        <h1 className={styles.headerTitle}>{t('Set up automatic invoicing')}</h1>
        <p className={styles.headerSubtitle}>
          {t('From now on we take care of your invoicing. So you can focus on the fun stuff.')}
        </p>
      </header>

      <div className={styles.listWrapper}>
        <h3 className={styles.listTitle}>{t('How it works')}</h3>

        <ol className={styles.list}>
          {
            listArray.map(({ id, text }, index) => (
              <li
                key={id}
                className={styles.listItem}
              >
                <span className={styles.listItemMarker}>{index + 1}</span>
                <p className={styles.listItemtext}>{text}</p>
              </li>
            ))
          }
        </ol>
      </div>

      <footer className={styles.footer}>
        <a
          className={styles.findOutMoreLink}
          href='/' // this should be link !
        >
          <span>{t('Find out more')}</span>
          <LinkIconSVG className={styles.icon} />
        </a>

        <button
          className={styles.getStartedButton}
          type='button'
          onClick={getStartedClick}
        >
          {t('get started')}
        </button>
      </footer>
    </div>
  );
};

export default SetUpAutomaticBillingModal;
