import * as types from '../constants/projects-overview-new';

const initialState = {
  isLoading: true,
  client: [],
  freelancer: [],
  admin: [],
  ratePlan: [],
  financialStatus: [],
  projects: {
    data: [],
    current_page: null,
    per_page: 10,
    total: null,
    last_page: null,
  },
  graphicsData: {},
};

export default function projectOverview(state = initialState, action) {
  switch (action.type) {
    case types.GET_FILTERS_DATA_SUCCESS: {
      const {
        payload: {
          client, freelancer, admin, ratePlan, financialStatus,
        },
      } = action;

      return {
        ...state, client, freelancer, admin, ratePlan, financialStatus, isLoading: false,
      };
    }
    case types.GET_FINANCIALS_TOTALS_SUCCESS:
      return {
        ...state,
        graphicsData: action.payload,
      };
    case types.GET_FINANCIALS_LIST_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };
    default:
      return state;
  }
}
