/**
 * Check if item is null or undefined
 * @param item
 * @return {item is null | undefined}
 */
const isEmpty = (item: any): item is null | undefined => item === null || item === undefined;

/**
 * Check if item is not null or undefined
 * @param item
 * @return {boolean}
 */
const isNotEmpty = <T>(item: T): item is Exclude<T, null | undefined> => (
  item !== null && item !== undefined
);

/**
 * Change key in object
 * @param {Object} object
 * @param {string} oldKey
 * @param {string} newKey
 * @return {Object}
 */
type ObjectKeyType = string | number | symbol;
type ChangedKeyObject<
  T,
  OldKey extends keyof T,
  NewKey extends ObjectKeyType,
> = Omit<T, OldKey> & {
  [key in NewKey]: T[OldKey]
};

type ChangeKey = <
  T extends Record<ObjectKeyType, any>,
  O extends keyof T,
  N extends ObjectKeyType,
>(
  object: T, oldKey: O, newKey: N,
) => ChangedKeyObject<T, O, ObjectKeyType>;
const changeKey: ChangeKey = (object, oldKey, newKey) => {
  const isOldKeyExist = Object.prototype.hasOwnProperty.call(object, oldKey);
  if (isOldKeyExist) {
    const { [oldKey]: value, ...otherProps } = object;
    return { ...otherProps, [newKey]: value };
  } else {
    return object;
  }
};

/**
 * Modify url to valid format (add protocol)
 * @param {string} url
 * @returns {string}
 */
type FormatURL = (url: string) => string;
const formatURL: FormatURL = (url) => {
  const protocolRegExp = /^https?:\/\//;
  const newUrl = protocolRegExp.test(url)
    ? url
    : `http://${url}`;

  return newUrl;
};

type GetItemByValue = <
  Item extends { [k in K]: V },
  V,
  K extends ObjectKeyType,
>(p: {
  array: Item[],
  keyToCheck: K,
  findValue: V,
}) => Item | undefined;
const getItemByValue: GetItemByValue = ({
  array,
  keyToCheck = 'id',
  findValue,
}) => array.find(({ [keyToCheck]: value }) => value === findValue);

type IsNotEmptyVisibleText = (htmlString?: string) => boolean;
const isNotEmptyVisibleText: IsNotEmptyVisibleText = (htmlString = '') => {
  const div: HTMLElement = document.createElement('DIV');
  div.innerHTML = htmlString;
  return div.innerText.length > 0;
};

/**
 * Capitalize word
 * @param {string} word
 * @returns {string}
 */
type Capitalize = (word: string) => string;
const capitalize: Capitalize = (word) => ((word && typeof word === 'string')
  ? `${word[0].toUpperCase()}${word.slice(1)}`
  : '');

type DateParamType = ConstructorParameters<typeof Date>[0];
type GetTimeWithTimezone = (time: DateParamType) => Date;
const getTimeWithTimezone: GetTimeWithTimezone = (time) => {
  const date: Date = new Date(time);
  const offsetMinutes: number = date.getTimezoneOffset();
  return offsetMinutes >= 0 ? new Date(time) : new Date(date.getTime() - offsetMinutes * 60000);
};

export {
  isEmpty,
  isNotEmpty,
  changeKey,
  formatURL,
  getItemByValue,
  isNotEmptyVisibleText,
  capitalize,
  getTimeWithTimezone,
};
