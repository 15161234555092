// MB = 1024 * KB = (1024 * KB) * 1024 B
import { FileValidationParams } from '../../hooks/useFiles';
import { OrNull, SVGType } from '../../types';
import MicrosoftDocIconSvg from '../../assets/svg/microsoft-word.svg';
import DocumentIconSvg from '../../assets/svg/documentFIle.svg';
import { client } from '../api';
import sentryLogger from '../../services/sentry';
import {
  IFile,
  ITranslationFile,
} from '../../components/projects/components/NewProject/AdditionalDocumentsField/interfaces';
import { AttachmentPropType, RestAttachmentItemType, ServerDocumentType } from './projectAttachments';

type SourceServerFileTypeInContextWrapper<F extends Omit<
AttachmentPropType,
'service_type_id' | 'couple'
>> = (
  ServerDocumentType
  & RestAttachmentItemType<F>
  );

type ExcludeUselessFieldsSourceFile =
  'created_at'
  | 'crowdin_project_file_id'
  | 'crowdin_storage_id'
  | 'deleted_at'
  | 'document_type'
  | 'is_public'
  | 'mime_type'
  | 'pivot'
  | 'size_in_kb'
  | 'updated_at'
  | 'user_type'
  | 'word_count';

export type SourceServerFileTypeInContext = SourceServerFileTypeInContextWrapper<Omit<IFile, ExcludeUselessFieldsSourceFile>>;

export const mbToB = (mb: number): number => mb * 1024 * 1024;

export enum MemorySize {
  Byte = 'b',
  KiloByte = 'kb',
  MegaByte = 'mb',
  GigaByte = 'gb',
}

export enum TranslationState {
  Translated = 'translated',
  Failed = 'failed',
  Loading = 'loading',
}

export type FileSizeObject = {
  unit: MemorySize,
  amount: number,
};

export const translationFilesHandler = (files: SourceServerFileTypeInContext[]): [ITranslationFile[], ITranslationFile[]] => {
  const canUseTranslationFlowFiles = files.filter((file: SourceServerFileTypeInContext) => file.can_use_translation_flow);
  const canUseTranslationFlowFilesWithState: ITranslationFile[] = canUseTranslationFlowFiles
    .map((item: SourceServerFileTypeInContext) => {
      const state = item.deeplTranslatedFile
        ? TranslationState.Translated
        : item.deepl_translation_failed
          ? TranslationState.Failed
          : TranslationState.Loading;

      return { ...item, state };
    });
  const translatedFilesWithState: ITranslationFile[] = canUseTranslationFlowFilesWithState.filter(
    (file: ITranslationFile) => file.state === TranslationState.Translated,
  );

  return [
    canUseTranslationFlowFilesWithState,
    translatedFilesWithState,
  ];
};

export const displayFileSize = (size: FileSizeObject): string => {
  const unitDisplayValue: { [key in MemorySize]: string } = {
    [MemorySize.Byte]: 'b',
    [MemorySize.KiloByte]: 'KB',
    [MemorySize.MegaByte]: 'MB',
    [MemorySize.GigaByte]: 'GB',
  };

  return `${size.amount} ${unitDisplayValue[size.unit]}`;
};

export const toByte = (size: number, type: MemorySize): number => {
  const sizeToByteMultiplier: { [key in MemorySize]: number } = {
    [MemorySize.Byte]: 1,
    [MemorySize.KiloByte]: 1024,
    [MemorySize.MegaByte]: 1024 ** 2,
    [MemorySize.GigaByte]: 1024 ** 3,
  };

  switch (type) {
    case MemorySize.Byte:
    case MemorySize.KiloByte:
    case MemorySize.MegaByte:
    case MemorySize.GigaByte:
      return sizeToByteMultiplier[type] * size;
    default:
      throw new Error(`Unsupported type '${type}'`);
  }
};

type SaveBlobType = ({ blob, fileName }: { blob: Blob, fileName?: string }) => void;
export const saveBlob: SaveBlobType = ({ blob, fileName = 'download' }) => {
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = downloadUrl;

  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

type SaveFileByURLType = ({ url, fileName }: { url: string, fileName?: string }) => void;
export const saveFileByURL: SaveFileByURLType = ({ url, fileName }) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => saveBlob({ blob, fileName }));
};

type SaveFileByEndpointType = ({ url, fileName }: { url: string, fileName?: string }) => Promise<void>;
export const saveFileByEndpoint: SaveFileByEndpointType = ({ url, fileName }) => (
  client.get(url, { responseType: 'blob' })
    .then((blob) => {
      blob instanceof Blob && saveBlob({ blob, fileName });
    })
    .catch(error => {
      sentryLogger.exceptionWithScope(error, { url });
      throw error.data;
    })
);

export const getFileExtension = (fileName: string): string => {
  // create extension (last . and ant word characters) into first group
  const extensionRegExp: RegExp = /\.(\w+)$/;
  // we use optional chaining (?.) and nullish coalescing (??) as .match(...) can return null
  return fileName.match(extensionRegExp)?.[1] ?? '';
};

export const getIconByFile = (fileName: string): SVGType => {
  const iconMappings: { [key: string]: SVGType } = {
    docx: MicrosoftDocIconSvg,
    doc: MicrosoftDocIconSvg,
  };
  const fileFormat: string = getFileExtension(fileName);
  return iconMappings[fileFormat] || DocumentIconSvg;
};

export const getProjectFilesValidationParams = (allowedExtensions: OrNull<string[]> = null): FileValidationParams => ({
  size: {
    amount: 100,
    unit: MemorySize.MegaByte,
  },
  type: allowedExtensions,
  totalSize: {
    amount: 1,
    unit: MemorySize.GigaByte,
  },
});
