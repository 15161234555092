import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PaymentDetailsPage.module.scss';
import SetUpAutomaticBillingModal from '../SetUpAutomaticBillingModal';
import PaymentDetailsHeader from './PaymentDetailsHeader';
import * as types from '../../constants';
import { RootState } from '../../store';
import { onCancelUpdating, onStartUpdating } from '../../actions/core';
import { getIframesUrls, getPayee } from './api';

const PaymentDetailsPage: FunctionComponent = () => {
  const [registrationIframeUrl, setRegistrationIframeUrl] = useState<string>('');
  const freelancerUser = useSelector((state: RootState) => state.auth.user);

  const isVATStatusSelected: boolean = !!freelancerUser.tipalti_vat_status && !!freelancerUser.tipalti_vat_type_id;
  const tipaltiCompleteOnboardingAt: string | null = freelancerUser.tipalti_complete_onboarding_at;
  const tipaltiId: number = freelancerUser.tipalti_id;
  const dispatch = useDispatch();

  const shouldShowGetStartedModal = !tipaltiId && !tipaltiCompleteOnboardingAt;
  const shouldShowRegistrationIframe = (!tipaltiId || !tipaltiCompleteOnboardingAt) || !isVATStatusSelected;

  useEffect(() => {
    !!freelancerUser.tipalti_id && getIframesUrls().then(({ IFRAME_URL_TYPE_MAIN: mainIframeUrl }) => {
      setRegistrationIframeUrl(mainIframeUrl);
    });
  }, []);

  const getStartedClick = () => {
    dispatch(onStartUpdating());

    getPayee().then(({ Idap }) => {
      getIframesUrls().then(({ IFRAME_URL_TYPE_MAIN }) => {
        dispatch({ type: types.UPDATE_TIPALTI_ID, payload: Idap });
        setRegistrationIframeUrl(IFRAME_URL_TYPE_MAIN);
      }).finally(() => dispatch(onCancelUpdating()));
    }).catch(() => dispatch(onCancelUpdating()));
  };

  return (
    <div className={styles.paymentDetails}>
      {
        shouldShowGetStartedModal && <SetUpAutomaticBillingModal getStartedClick={getStartedClick} />
      }

      {
        shouldShowRegistrationIframe && !shouldShowGetStartedModal && (
        <>
          <PaymentDetailsHeader profileId={freelancerUser.id} />
          <iframe
            id='tipalti-iframe'
            title='tipalti-iframe'
            src={registrationIframeUrl}
            className={styles.tipaltiIframe}
          />
        </>
        )
      }

    </div>
  );
};

export default PaymentDetailsPage;
