import * as types from '../constants/freelancer-overview-tab-new';

const initState = {
  isLoading: true,
  financialStatus: [],
  client: [],
  projects: {
    data: [],
    current_page: null,
    per_page: 10,
    total: null,
    last_page: null,
  },
  completeProjects: 0,
  graphicsData: {},
};

const freelancerFinancialOverviewNew = (state = initState, action) => {
  switch (action.type) {
    case types.GET_FREELANCER_FILTERS_DATA_SUCCESS: {
      const { payload: { client, financialStatus } } = action;

      return {
        ...state, client, financialStatus, isLoading: false,
      };
    }
    case types.GET_FREELANCER_FINANCIALS_LIST_SUCCESS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case types.GET_FREELANCER_FINANCIALS_TOTALS_SUCCESS: {
      const { complete_projects: completeProjects = 0, ...graphicsData } = action.payload;

      return {
        ...state,
        completeProjects,
        graphicsData,
      };
    }
    default:
      return state;
  }
};

export default freelancerFinancialOverviewNew;
