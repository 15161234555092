export const GET_CLIENT_PROFILE_SUCCESS = 'GET_CLIENT_PROFILE_SUCCESS';
export const GET_COMPANY_PROFILE_SUCCESS = 'GET_COMPANY_PROFILE_SUCCESS';
export const UPDATE_CLIENT_PROFILE = 'UPDATE_CLIENT_PROFILE';
export const UPDATE_COMPANY_PROFILE = 'UPDATE_COMPANY_PROFILE';
export const REMOVE_CLIENT_FILE_SUCCESS = 'REMOVE_CLIENT_FILE_SUCCESS';
export const REMOVE_COMPANY_FILE_SUCCESS = 'REMOVE_COMPANY_FILE_SUCCESS';
export const ADD_CLIENT_FILE_SUCCESS = 'ADD_CLIENT_FILE_SUCCESS';
export const ADD_COMPANY_FILE_SUCCESS = 'ADD_COMPANY_FILE_SUCCESS';
export const ADD_CLIENT_USER_SUCCESS = 'ADD_CLIENT_USER_SUCCESS';
export const REMOVE_CLIENT_USER_SUCCESS = 'REMOVE_CLIENT_USER_SUCCESS';
export const UPDATE_CLIENT_USER_SUCCESS = 'UPDATE_CLIENT_USER_PROFILE_SUCCESS';
export const UPDATE_CLIENT_MAIN_USER = 'UPDATE_CLIENT_MAIN_USER';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const GET_WRITER_PROFILE_SUCCESS = 'GET_WRITER_PROFILE_SUCCESS';
export const UPDATE_WRITER_PROFILE = 'UPDATE_WRITER_PROFILE';
export const DELETE_WRITER_SUCCESS = 'DELETE_WRITER_SUCCESS';
export const UPDATE_DESIGNER_PROFILE = 'UPDATE_DESIGNER_PROFILE';
export const DELETE_DESIGNER_SUCCESS = 'DELETE_DESIGNER_SUCCESS';
export const GET_DESIGNER_PROFILE_SUCCESS = 'GET_DESIGNER_PROFILE_SUCCESS';
export const GET_PROFESSIONAL_PROFILE_SUCCESS = 'GET_PROFESSIONAL_PROFILE_SUCCESS';
export const UPDATE_PROFESSIONAL_PROFILE = 'UPDATE_PROFESSIONAL_PROFILE';
export const GET_ADMIN_PROFILE_SUCCESS = 'GET_ADMIN_P4ROFILE_SUCCESS';
export const UPDATE_ADMIN_PROFILE = 'UPDATE_ADMIN_PROFILE';
// in admin.js the same const
// export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const ADD_FILE_TO_PORTFOLIO_SUCCESS = 'ADD_FILE_TO_PORTFOLIO_SUCCESS';
export const REMOVE_FILE_FROM_PORTFOLIO_SUCCESS = 'REMOVE_FILE_FROM_PORTFOLIO_SUCCESS';
export const ADD_FILE_TO_DESIGNER_PORTFOLIO_SUCCESS = 'ADD_FILE_TO_DESIGNER_PORTFOLIO_SUCCESS';
export const REMOVE_FILE_FROM_DESIGNER_PORTFOLIO_SUCCESS = 'REMOVE_FILE_FROM_DESIGNER_PORTFOLIO_SUCCESS';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_ADMIN_CLIENT_SUCCESS = 'UPDATE_ADMIN_CLIENT_SUCCESS';
// TODO remove as used only in action, which cannot been called
export const ADD_ADMIN_TO_CLIENT = 'ADD_ADMIN_TO_CLIENT';
export const SET_CSM_LEADING = 'SET_CSM_LEADING';
export const REMOVE_ADMIN_FROM_CLIENT = 'REMOVE_ADMIN_FROM_CLIENT';
export const ADD_WATCHER_TO_CLIENT = 'ADD_WATCHER_TO_CLIENT';
export const REMOVE_WATCHER_FROM_CLIENT = 'REMOVE_WATCHER_FROM_CLIENT';
export const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const UPDATE_CLIENT_AVAILABILITY = 'UPDATE_CLIENT_AVAILABILITY';
export const CHANGE_CLIENT_STATE_SUCCESS = 'CHANGE_CLIENT_STATE_SUCCESS';
export const GET_WRITER_FINANCIALS = 'GET_WRITER_FINANCIALS';
export const GET_WRITER_PLAN_CLIENTS = 'GET_WRITER_PLAN_CLIENTS';
export const GET_WRITER_PLANS_URL = 'GET_WRITER_PLANS_URL';
export const SET_WRITER_PLANS_URL = 'SET_WRITER_PLANS_URL';
export const GET_WRITER_CLIENT_PLAN_URL = 'GET_WRITER_CLIENT_PLAN_URL';
export const SET_WRITER_CLIENT_PLAN_URL = 'SET_WRITER_CLIENT_PLAN_URL';
export const SET_WRITER_PLANS_PAGE = 'SET_WRITER_PLANS_PAGE';
export const GET_WRITER_VIEW = 'GET_WRITER_VIEW';
export const GET_FREELANCER_PROJECTS_REPORTS = 'GET_FREELANCER_PROJECTS_REPORTS';
export const GET_WRITER_VIEW_URL = 'GET_WRITER_VIEW_URL';
export const SET_WRITER_VIEW_URL = 'SET_WRITER_VIEW_URL';
export const GET_COMPANY_DEPARTMENTS_SUCCESS = 'GET_COMPANY_DEPARTMENTS_SUCCESS';
export const ADD_COMPANY_DEPARTMENTS_SUCCESS = 'ADD_COMPANY_DEPARTMENTS_SUCCESS';
export const DELETE_COMPANY_DEPARTMENTS_SUCCESS = 'DELETE_COMPANY_DEPARTMENTS_SUCCESS';
export const UPDATE_COMPANY_DEPARTMENTS_SUCCESS = 'UPDATE_COMPANY_DEPARTMENTS_SUCCESS';
export const UPDATE_WRITER_STATUS = 'UPDATE_WRITER_STATUS';
export const UPDATE_DESIGNER_STATUS = 'UPDATE_DESIGNER_STATUS';
export const DEACTIVATE_WRITER = 'DEACTIVATE_WRITER';
export const DEACTIVATE_DESIGNER = 'DEACTIVATE_DESIGNER';
export const GET_FREELANCER_EXPERIENCES_SUCCESS = 'GET_FREELANCER_EXPERIENCES_SUCCESS';
export const ADD_FREELANCER_EXPERIENCE_SUCCESS = 'ADD_FREELANCER_EXPERIENCE_SUCCESS';
export const UPDATE_FREELANCER_EXPERIENCE_SUCCESS = 'UPDATE_FREELANCER_EXPERIENCE_SUCCESS';
export const DELETE_FREELANCER_EXPERIENCE_SUCCESS = 'DELETE_FREELANCER_EXPERIENCE_SUCCESS';
export const GET_FREELANCER_HIGHLIGHTS_SUCCESS = 'GET_FREELANCER_HIGHLIGHTS_SUCCESS';
export const GET_FREELANCER_PORTFOLIO_WORK_SUCCESS = 'GET_FREELANCER_PORTFOLIO_WORK_SUCCESS';
export const ADD_FREELANCER_PORTFOLIO_WORK_SUCCESS = 'ADD_FREELANCER_PORTFOLIO_WORK_SUCCESS';
export const UPDATE_FREELANCER_PORTFOLIO_WORK_SUCCESS = 'UPDATE_FREELANCER_PORTFOLIO_WORK_SUCCESS';
export const DELETE_FREELANCER_PORTFOLIO_WORK_SUCCESS = 'DELETE_FREELANCER_PORTFOLIO_WORK_SUCCESS';
export const GET_FREELANCER_BILLING_INFO_SUCCESS = 'GET_FREELANCER_BILLING_INFO_SUCCESS';
export const UPDATE_FREELANCER_BILLING_INFO_SUCCESS = 'UPDATE_FREELANCER_BILLING_INFO_SUCCESS';
export const UPDATE_CLIENT_STATUS_SUCCESS = 'UPDATE_CLIENT_STATUS_SUCCESS';
export const UPDATE_WRITER_IS_TIPALTI_ONBOARDING_DISABLED = 'UPDATE_WRITER_IS_TIPALTI_ONBOARDING_DISABLED';
export const UPDATE_DESIGNER_IS_TIPALTI_ONBOARDING_DISABLED = 'UPDATE_DESIGNER_IS_TIPALTI_ONBOARDING_DISABLED';
