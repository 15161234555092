import React from 'react';
import classNames from 'classnames';
import { POSITIONS } from '../../../../utils';
import styles from './MessageTooltip.module.scss';

const MessageTooltip = ({
  content,
  className,
  messageClassName,
  arrowClassName,
  position = POSITIONS.BOTTOM,
}) => {
  const positionStyle = {
    [POSITIONS.TOP]: styles.top,
    [POSITIONS.RIGHT]: styles.right,
    [POSITIONS.BOTTOM]: styles.bottom,
    [POSITIONS.LEFT]: styles.left,
  };
  return (
    <div className={classNames(styles.messageBlockWrapper, positionStyle[position], className)}>
      <div className={classNames(styles.messageBlock, messageClassName)}>
        <div className={classNames(styles.arrow, arrowClassName)} />
        {content}
      </div>
    </div>
  );
};

export default MessageTooltip;
