import { toast } from 'react-toastify';
import sentryLogger from '../services/sentry';
import * as typesOld from '../constants/freelancer-overview-tab-old';
import * as types from '../constants/freelancer-overview-tab-new';
import { showHideWarning } from './warning-bar';
import { client, getData, redirectToFinancial } from '../utils';

const getQueryParams = (filters, perPage, orderBy, sortType) => {
  const queryParams = Object.entries(filters).reduce((accumulator, [key, values]) => (
    values.length
      ? typeof values === 'string'
        ? `${accumulator}&${key}=${values}`
        : `${accumulator}&${key}=${values.join(',')}`
      : accumulator
  ), '');

  return `${queryParams}&resultsPerPage=${perPage}&orderBy=${orderBy}&sort=${sortType}`;
};

export function getFreelancerFiltersData(id) {
  return (dispatch, getState) => {
    const { user } = getState().auth;

    const clientUrl = `/freelancer/clients-list/${id}`;
    const financialStatusUrl = '/financialStatus';

    const payload = {};

    const clientPromise = getData(clientUrl, user)
      .then(data => {
        payload.client = data;
      });

    const financialStatusPromise = getData(financialStatusUrl, user)
      .then(data => {
        payload.financialStatus = data;
      });

    Promise.all([clientPromise, financialStatusPromise])
      .then(() => {
        dispatch({
          type: typesOld.OLD_GET_FREELANCER_FILTERS_DATA_SUCCESS,
          payload,
        });
        dispatch({
          type: types.GET_FREELANCER_FILTERS_DATA_SUCCESS,
          payload,
        });
      });
  };
}

export function getCompleteProjects(id) {
  return (dispatch, getState) => {
    const { user } = getState().auth;

    const url = `/freelancer/complete-projects/${id}`;

    return client.get(url)
      .then((data) => {
        const isWarningVisible = data.data.result.completeProjects > 0;
        dispatch(showHideWarning({
          isVisible: isWarningVisible,
          amount: data.data.result.completeProjects,
          onClickString: `() => {
            const redirectToFinancial = ${redirectToFinancial.toString()}
            ${isWarningVisible} && redirectToFinancial(${id});
          }`,
          functionContext: null,
        }));
        localStorage.setItem('show_freelancer_warning', String(data.data.result.completeProjects > 0));
        localStorage.setItem('uncomplete_umount', data.data.result.completeProjects);
        return data.data.result;
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw error.data;
      });
  };
}

export const freelancerSendProjectsPdf = (freelancerId, period) => (dispatch, getState) => {
  const { user } = getState().auth;
  const url = `/freelancer/${freelancerId}/send-projects-pdf`;

  const requestData = {
    period,
  };

  return client.post(url, requestData)
    .then((data) => data.data.result)
    .catch(error => {
      sentryLogger.exceptionWithScope(error, { user, url });
      throw new Error(error);
    });
};

export const freelancerDownloadProjectsPdf = (freelancerId, period) => (dispatch, getState) => {
  const { user } = getState().auth;
  const url = `/freelancer/${freelancerId}/download-projects-pdf`;

  const requestData = {
    period: period[0],
  };

  return client.post(url, requestData, { responseType: 'blob' })
    .then(result => {
      const downloadUrl = window.URL.createObjectURL(result);
      const a = document.createElement('a');

      a.href = downloadUrl;

      a.download = `Financial Report ${period[0]} - ${freelancerId}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch(error => {
      sentryLogger.exceptionWithScope(error, { user, url });
      throw new Error(error?.message);
    });
};

export const freelancerUpdateTipaltiInvoice = (freelancerId, month, year) => (dispatch, getState) => {
  const { user } = getState().auth;
  const url = `/tipalti/update-invoice/${freelancerId}`;
  const requestData = {
    month,
    year,
  };

  return client.post(url, requestData)
    .then(data => {
      if (data.data.success) {
        toast.info('Invoice has been updated successfully', { autoClose: false, position: 'bottom-left' });
      }
    })
    .catch(error => {
      sentryLogger.exceptionWithScope(error, { user, url });
      throw error.data;
    });
};

export function getFreelancerFinancialList(freelancerId, filters, sortType = 'desc', orderBy = 'financial_completion_date') {
  return (dispatch, getState) => {
    const { user } = getState().auth;

    const queryParams = getQueryParams(filters, 10, orderBy, sortType);
    const url = `/freelancer-financial/${freelancerId}/list?${queryParams}`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_FREELANCER_FINANCIALS_LIST_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw error.data;
      });
  };
}

export function getFreelancerFinancialTotals(freelancerId, filters, sortType = 'desc', orderBy = 'created_at') {
  return (dispatch, getState) => {
    const { user } = getState().auth;

    const queryParams = getQueryParams(filters, 10, orderBy, sortType);
    const url = `/freelancer-financial/${freelancerId}/totals?${queryParams}`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_FREELANCER_FINANCIALS_TOTALS_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw error.data;
      });
  };
}
