import React, { forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import Portal from '../Portal';
import CloseButtonIconSVG from '../../../../assets/svg/cross-out.svg';

import styles from './Modal.module.scss';

interface ModalProps {
  onBackdropClick: () => void,
  className?: string,
  children: React.ReactNode,
  withoutCloseButton?: boolean,
  classNameModal?: string,
  wrapperClassName?: string,
  dataTestId?: string,
}

const Modal = forwardRef<HTMLDivElement, ModalProps>((
  {
    onBackdropClick,
    className = '',
    children,
    withoutCloseButton = false,
    classNameModal = '',
    wrapperClassName = '',
    dataTestId,
  },
  ref,
): JSX.Element => {
  const handleBackdropClick = (event): void => {
    if (onBackdropClick) {
      const { target, currentTarget } = event;
      const shouldCloseModal = target === currentTarget;
      shouldCloseModal && onBackdropClick();
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return function clear() {
      document.body.style.overflow = 'auto';
      document.body.style.overflowX = 'hidden';
    };
  }, []);

  return (
    <Portal className={classNames(styles.modal, wrapperClassName)}>
      <div
        role='button'
        tabIndex={-1}
        onMouseDown={handleBackdropClick}
        className={classNames(styles.backdrop, classNameModal)}
        data-test-id={dataTestId}
      >
        <div
          className={classNames(className, styles.content)}
          ref={ref}
        >
          {
            !withoutCloseButton
              && (
              <button
                className={styles.closeButton}
                type='button'
                onClick={onBackdropClick}
                data-test-id={`${dataTestId}__close`}
              >
                <CloseButtonIconSVG />
              </button>
              )
          }

          {children}
        </div>
      </div>
    </Portal>
  );
});

export default Modal;
