export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';

export const WRITING_ID = 1;
export const EDITING_ID = 2;
export const LOCALISATION_ID = 3;
export const REWRITING_ID = 4;
export const DESIGN_ID = 5;
export const AUDIO_TRANSCRIPTION_ID = 6;
// TODO
export const ADDITIONAL_SERVICES_ID = 8;
export const PROFESSIONAL_SERVICE_ID = 8;
export const TRANSLATION_ID = 9;

export const WRITER_EVALUATION_SERVICES = {
  WRITING: WRITING_ID,
  EDITING: EDITING_ID,
  LOCALISATION: LOCALISATION_ID,
  REWRITING: REWRITING_ID,
};
