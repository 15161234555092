import React from 'react';
import classNames from 'classnames';
import MessageTooltip from '../MessageTooltip';
import { POSITIONS } from '../../../../utils';
import styles from './InfoBlock.module.scss';

/**
 * @type {React.FunctionComponent<{
 * children: ReactNode,
 * tooltipContent?: ReactNode,
 * position?: string,
 * className?: string,
 * tooltipClassName?: string,
 * messageClassName?: string,
 * arrowClassName?: string,
 * isOpened?: boolean,
 * withoutHover?: boolean
 * }>}
 */
const InfoBlock = ({
  children,
  tooltipContent = null,
  position = POSITIONS.BOTTOM,
  className = '',
  tooltipClassName = '',
  messageClassName = '',
  arrowClassName = '',
  isOpened = false,
  withoutHover = false,
}) => (
  <div
    className={classNames(
      className,
      styles.infoBlock,
      {
        [styles.pointer]: tooltipContent,
        [styles.opened]: isOpened,
        [styles.withoutHover]: withoutHover,
      },
    )}
    data-test-id={tooltipContent ? 'infoBlock-pointer' : 'infoBlock'}
  >
    {children}
    {
      tooltipContent && (
        <MessageTooltip
          position={position}
          content={tooltipContent}
          className={classNames(styles.tooltip, tooltipClassName)}
          messageClassName={messageClassName}
          arrowClassName={arrowClassName}
        />
      )
    }
  </div>
);

export default InfoBlock;
