import * as types from '../constants';

const initialState = [];

export function tools(state = initialState, action) {
  switch (action.type) {
    case types.GET_TOOLS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

const initialStateToolsById = {};

export function toolsById(state = initialStateToolsById, action) {
  switch (action.type) {
    case types.GET_TOOLS_SUCCESS:
      return action.payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    default:
      return state;
  }
}

export default tools;
