import React from 'react';
import { connect } from 'react-redux';

const UpdatingLoader = (props) => {
  const { isUpdating } = props;

  return (
    isUpdating && (
      <div className='update-loader-container'>
        <div className='loader-container'>
          <div className='loader' />
        </div>
        <span>Would you like some fries while waiting?</span>
      </div>
    )
  );
};

const mapStateToProps = state => ({
  isUpdating: state.core.updatingLoader.isUpdating,
});

export default connect(mapStateToProps, null)(UpdatingLoader);
