import * as types from '../constants/freelancers';

const initialState = {
  users: [],
  isLoading: true,
};

export default function freelancers(state = initialState, action) {
  switch (action.type) {
    case types.GET_FREELANCERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
