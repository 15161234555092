import { AxiosRequestConfig } from 'axios';
import { client } from '../../utils';
import { DocumentType } from '../../utils/constants/documentType';

type UploadFileToDraftApi = (p: {
  draftId: number;
  file: File;
  documentType: DocumentType;
}, config?: AxiosRequestConfig) => Promise<UploadedFileResponse>;

export type UploadedFileResponse = {
  id: number,
  title: string,
  size: number,
  word_count: number,
  custom_properties: { documentType: DocumentType },
};
export const uploadFileToDraftApi: UploadFileToDraftApi = ({ draftId, file, documentType }, configs) => {
  const url: string = `/draft/${draftId}/add-file`;

  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  formData.append('document_type', documentType);

  return client.post(url, formData, configs)
    .then(({ data }) => data.result);
};

type DeleteFileFromDraftApi = (p: {
  draftId: number;
  fileId: number;
}) => Promise<void>;
export const deleteFileFromDraftApi: DeleteFileFromDraftApi = ({ draftId, fileId }) => {
  const url: string = `/draft/${draftId}/delete-file/${fileId}`;

  return client.delete(url)
    .then(data => data.data.result);
};
