import _ from 'lodash';
import * as types from '../constants';
import sentryLogger from '../services/sentry';
import { client, quest } from '../utils';

export function getLanguages() {
  return (dispatch) => {
    client.get('/onboarding/language')
      .then(data => {
        dispatch({
          type: types.GET_ONBOARDING_LANGUAGES_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_LANGUAGES_FAILURE,
          payload: error,
        });
      });
  };
}

export function getContentTypes() {
  return (dispatch) => {
    client.get('/onboarding/contentType')
      .then(data => {
        dispatch({
          type: types.GET_ONBOARDING_CONTENT_TYPES_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_CONTENT_TYPES_FAILURE,
          payload: error,
        });
      });
  };
}

export function getServices() {
  return (dispatch) => {
    client.get('/onboarding/service')
      .then(data => {
        dispatch({
          type: types.GET_ONBOARDING_SERVICES_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_SERVICES_FAILURE,
          payload: error,
        });
      });
  };
}

export function setWriterIndustry({
  userId, isExperience, industryId,
}) {
  return () => {
    const url = '/user-experience';

    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('industry_id', industryId);
    formData.append('is_experience', isExperience);

    return client.post(url, formData)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  };
}

export function removeWriterIndustry(industryId) {
  return () => {
    const url = `/user-experience/${industryId}`;

    return client.delete(url)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  };
}

export function updateWriterSpeciality({
  pairId, isExperience, userId, industryId, specialities,
}) {
  return () => {
    const url = `/user-experience/${pairId}`;

    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('industry_id', industryId);
    formData.append('is_experience', isExperience);
    specialities.forEach(id => {
      formData.append('specialities[]', id);
    });

    formData.append('_method', 'PUT');

    return client.post(url, formData)
      .then((response) => response)
      .catch((error) => {
        throw new Error(error);
      });
  };
}

export function getIndustries() {
  return (dispatch) => {
    client.get('/onboarding/industry')
      .then(data => {
        dispatch({
          type: types.GET_ONBOARDING_INDUSRIES_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_INDUSRIES_FAILURE,
          payload: error,
        });
      });
  };
}

export function getLocalisationSofware() {
  return (dispatch) => {
    client.get('/localisation-software')
      .then(data => {
        dispatch({
          type: types.GET_ONBOARDING_LOCALISATION_SOFTWARE_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_LOCALISATION_SOFTWARE_FAILURE,
          payload: error,
        });
      });
  };
}

export function getSeoOptimaze() {
  return (dispatch) => {
    client.get('/seo-optimize')
      .then(data => {
        dispatch({
          type: types.GET_ONBOARDING_SEO_OPTIMAZE_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_SEO_OPTIMAZE_FAILURE,
          payload: error,
        });
      });
  };
}

export function getSignUpSource() {
  return (dispatch) => {
    client.get('/user/sing-up-source')
      .then((data) => {
        dispatch({
          type: types.GET_ONBOARDING_SOURCE_SUCCESS,
          payload: data.data.result,
        });
      })
      .catch(error => {
        dispatch({
          type: types.GET_ONBOARDING_SOURCE_FAILURE,
          payload: error,
        });
      });
  };
}

// unused params history, lang
export function createFreelancer(data) {
  return (dispatch) => {
    const url = '/user/store-freelancer';

    dispatch({
      type: types.CREATE_NEW_FREELANCER_FAILURE,
      payload: '',
    });

    return (
      quest.post(url, data)
        .then((response) => {
          if (response.data.success) {
            return response.data.result;
          }
        })
        .catch((error) => {
          sentryLogger.exceptionWithScope(error, { url });
          const emailError = _.get(error, 'data.data.errors.email[0]');

          dispatch({
            type: types.CREATE_NEW_FREELANCER_FAILURE,
            payload: emailError || 'Something went wrong.',
          });
        })
    );
  };
}

export function updateFreelancerSignUpData(data, confirm = false) {
  return (dispatch) => (
    client.post(`/onboarding/user`, data)
      .then(response => {
        if (confirm) {
          dispatch({
            type: types.UPDATE_USER_STATE,
            payload: response.data.result.object.user.state,
          });
        }
        return response.data.result;
      })
      .catch(() => {
      })
  );
}

// Profile picture

export function updateFreelancerAvatar(id, formData) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = `/writer/${id}`;

    formData.append('_method', 'PUT');

    return client.post(url, formData)
      .then((data) => {
        dispatch({
          type: types.UPDATE_FREELANCER_AVATAR_SUCCESS,
          payload: data.data.result.user.avatar,
        });
        return data.data.result.user.avatar;
      })
      .catch((error) => {
        sentryLogger.exceptionWithScope(error, { user, url });
        dispatch({
          type: types.UPDATE_FREELANCER_AVATAR_FAILURE,
          payload: error,
        });
      });
  };
}

export function removeFreelancerAvatar() {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = `/user/${user.id}/destroy-avatar`;

    return client.delete(url)
      .then((data) => {
        dispatch({
          type: types.REMOVE_FREELANCER_AVATAR_SUCCESS,
          payload: data.data.result.avatar,
        });
        return data.data.result.avatar;
      })
      .catch((error) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

// Showcase: Assessments

export function getAssessmentWritingExercise(nativeLanguageId, serviceTypeId) {
  return (dispatch) => {
    const url = `/onboarding/writer/assessment-exercise-file?native_language_id=${nativeLanguageId}&service_type_id=${serviceTypeId}`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ONBOARDING_ASSESSMENT_WRITING_EXERCISE_FAILURE,
          payload: error,
        });
      });
  };
}

export function getAssessmentTranslatingExercise(translateToLanguageId, serviceTypeId) {
  return (dispatch) => {
    const url = `/onboarding/writer/assessment-exercise-file?source_language_id=${translateToLanguageId}&service_type_id=${serviceTypeId}`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ONBOARDING_ASSESSMENT_TRANSLATING_EXERCISE_FAILURE,
          payload: error,
        });
      });
  };
}

export function getUserAssessmentWork() {
  return (dispatch) => {
    const url = `/onboarding/writer/assessment-exercise-work`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

export function removeUserAssessmentWork(id) {
  return (dispatch) => {
    const url = `/onboarding/writer/assessment-exercise-work/${id}`;

    return client.delete(url)
      .then((data) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: data.data.result,
        });
        return data;
      })
      .catch((error) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

export function uploadUserAssessmentWork(data) {
  return (dispatch) => {
    const url = `/onboarding/writer/assessment-exercise-work`;

    return client.post(url, data)
      .then((response) => {
        dispatch({
          type: types.UPLOAD_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: response.data.result,
        });
        return response.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.UPLOAD_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

// Showcase: Work examples

export function getUserWorkExamplesFile() {
  return (dispatch) => {
    const url = `/onboarding/writer/work-example-file?isTask=0`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_ONBOARDING_WORK_EXAMPLES_FILE_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ONBOARDING_WORK_EXAMPLES_FILE_FAILURE,
          payload: error,
        });

        throw new Error(error);
      });
  };
}

export function getUserWorkExamplesFileByWriterId(writerId) {
  return () => {
    const url = `/onboarding/writer/work-example-file`;
    const params = { writer_id: writerId };

    return client.get(url, { params })
      .then((data) => data.data.result);
  };
}

export function getUserWorkExamplesLink() {
  return (dispatch) => {
    const url = `/onboarding/writer/work-example-link`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.GET_ONBOARDING_WORK_EXAMPLES_LINK_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ONBOARDING_WORK_EXAMPLES_LINK_FAILURE,
          payload: error,
        });
      });
  };
}

export function removeUserWorkExamplesFile(id) {
  return (dispatch) => {
    const url = `/onboarding/writer/work-example-file/${id}`;

    return client.delete(url)
      .then((data) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: data.data.result,
        });
        return data;
      })
      .catch((error) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

export function removeUserWorkExamplesLink(id) {
  return (dispatch) => {
    const url = `/onboarding/writer/work-example-link/${id}`;

    return client.delete(url)
      .then((data) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: data.data.result,
        });
        return data;
      })
      .catch((error) => {
        dispatch({
          type: types.REMOVE_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

export function uploadUserWorkExamplesFile(data) {
  return (dispatch) => {
    const url = `/onboarding/writer/work-example-file`;

    return client.post(url, data)
      .then((response) => {
        dispatch({
          type: types.UPLOAD_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: response.data.result,
        });
        return response.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.UPLOAD_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });

        throw Error(error);
      });
  };
}

export function uploadUserWorkExamplesLink(data) {
  return (dispatch) => {
    const url = `/onboarding/writer/work-example-link`;

    return client.post(url, data)
      .then((response) => {
        dispatch({
          type: types.UPLOAD_ONBOARDING_ASSESSMENT_WORK_SUCCESS,
          payload: response.data.result,
        });
        return response.data.result;
      })
      .catch((error) => {
        dispatch({
          type: types.UPLOAD_ONBOARDING_ASSESSMENT_WORK_FAILURE,
          payload: error,
        });
      });
  };
}

export function getFreelancerData(id) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = `/writer/${id}`;

    return client.get(url)
      .then((data) => {
        dispatch({
          type: types.RECEIVE_ONBOARDING_DATA_SUCCESS,
          payload: data.data.result,
        });
        return data.data.result;
      })
      .catch((error) => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw new Error(error);
      });
  };
}
