import * as types from '../constants/rate-plans';

const initialState = {
  isLoading: true,
  client: [],
  plan: {},
  priorities: [], // ListItemWithTitle[]
  allRatePlans: [],
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: 10,
  allClients: [],
  contract: {},
  paymentTerms: [],
  isEdit: false,
  isUpdating: false,
};

export default function ratePlans(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_CLIENTS:
      return { ...state, allClients: action.payload.data };
    case types.GET_CLIENTS:
    case types.SET_PAGE_CLIENTS_PAGE_SUCCESS:
    case types.FILTER_CLIENTS_BY_SUCCESS:
    case types.SORT_CLIENTS_SUCCESS:
      return {
        ...state,
        client: action.payload.data,
        isLoading: false,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
      };
    case types.ENABLE_EDIT_MODE:
      return {
        ...state,
        isEdit: true,
      };
    case types.DISABLE_EDIT_MODE:
      return {
        ...state,
        isEdit: false,
      };
    case types.SET_PLANS_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case types.HIDE_PLANS_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case types.SAVE_PRICE_PLAN:
    case types.SAVE_PLAN_SERVICE_OPTIONS:
    case types.FILTER_DEFAULT_RATE_PLAN:
    case types.SAVE_PRIORITY:
    case types.UPDATE_PRIORITY:
    case types.SAVE_DISCOUNTS:
    case types.DUPLICATE_PRICE_PLAN_SERVICE:
    case types.GET_DEFAULT_RATE_PLAN:
    case types.GET_CLIENT_RATE_PLANS:
      return { ...state, plan: action.payload, isLoading: false };
    case types.GET_PRIORITIES:
      return { ...state, priorities: action.payload };
    case types.GET_ALL_RATE_PLANS:
      return { ...state, allRatePlans: action.payload };
    case types.GET_CLIENTS_URL:
    case types.SET_CLIENTS_PAGE_URL:
    case types.FILTER_CLIENTS_BY_SUCCESS_URL:
    case types.SORT_CLIENTS_URL:
      return { ...state, url: action.payload };
    case types.GET_CONTRACT:
    case types.UPDATE_CONTRACT:
      return { ...state, contract: action.payload };
    case types.GET_PAYMENT_TERMS:
      return { ...state, paymentTerms: action.payload };
    default:
      return state;
  }
}
