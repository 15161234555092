import React, { lazy } from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TranslatorProvider } from 'react-translate';
import { hotjar } from 'react-hotjar';
import markerSDK from '@marker.io/browser';
import classnames from 'classnames';
import translationsEn from '../../i18n/en.json';
import translationsDe from '../../i18n/de.json';

import PublicRoute from '../PublicRoute';
import PrivateRoute from '../../containers/PrivateRoute';
import history from '../../helpers/history';

import LoadingScreen from '../core/components/LoadingScreen';
import { AVAILABLE_LANGUAGES, LANGUAGE_CODES } from '../../constants';

import SignUpRoute from '../../containers/SignUpRoute';
// import NewProject from '../../containers/NewProject';
import { USER_ROLES } from '../../utils';
import { useInitMixpanel } from '../../hooks';
import PaymentDetailsPage from '../PaymentDetailsPage';
import { isUserFreelancerOrProfessional } from '../../utils/helpers/userRoles';

// lazy components
const AuthPage = lazy(() => import('../../containers/Authorization/AuthPage'));
const MaintenancePage = lazy(() => import('../MaintenancePage'));
const ResetPassword = lazy(() => import('../../containers/Authorization/ResetPassword'));
const NewPassword = lazy(() => import('../../containers/Authorization/NewPassword'));
const SignUpPage = lazy(() => import('../../containers/Authorization/SignUpPage'));
const ProfileDeactivatedPage = lazy(() => import('../registration/core/ProfileDeactivatedPage'));
const ConfirmEmailPage = lazy(() => import('../registration/core/ConfirmEmailPage'));
const ResetPasswordConfirmation = lazy(() => import('../authorization/components/ResetPasswordConfirmation'));
const NewProjectNew = lazy(() => import('../projects/components/NewProject/NewProjectNew'));
const AccountPage = lazy(() => import('../profile/components/UserProfile/FreelancerProfile/AccountPage'));

const DefaultRatePlanDetails = lazy(() => import('../dashboard/components/SettingsTab/FinancialTab/RatePlans/DefaultRatePlanDetails'));
const Dashboard = lazy(() => import('../../containers/Dashboard'));
// const NewProject = lazy(() => import('../../containers/NewProject'));
const ProjectDetails = lazy(() => import('../../containers/ProjectDetails'));
const DesignDetails = lazy(() => import('../../containers/DesignDetails'));
const UserProfile = lazy(() => import('../profile/components/UserProfile'));
const AddUser = lazy(() => import('../../containers/AddUser'));
const UserSettings = lazy(() => import('../profile/components/UserSettings'));
const AddCopywriterToTeam = lazy(() => import('../../containers/AddCopywriterToTeam'));
const AddDesignerToTeam = lazy(() => import('../AddDesignerToTeam/AddDesignerToTeam'));
const NewBrainstorm = lazy(() => import('../../containers/NewBrainstorm'));
const BrainstormDetails = lazy(() => import('../../containers/BrainstormDetails'));
const FreelancerProfile = lazy(() => import('../profile/components/UserProfile/FreelancerProfile'));
const OneOffProjectDetails = lazy(() => import('../../containers/OneOffProjectDetails'));
const EvaluationProjectDetails = lazy(() => import('../../containers/EvaluationProjectDetails'));
const ConfirmationPage = lazy(() => import('../registration/core/ConfirmationPage'));

const RegistrationStepList = lazy(() => import('../../containers/RegistrationStepList'));
const GetStartedPage = lazy(() => import('../registration/core/GetStartedPage'));

const widget = async () => {
  const destination = process.env.REACT_APP_MARKER_DESTINATION;
  if (!destination) {
    throw new Error('REACT_APP_MARKER_DESTINATION not set in env');
  }

  return markerSDK.loadWidget({
    destination,
    source: 'snippet',
    beta: {
      sessionReplay: true,
    },
  });
};

// get is maintenance from ENV
const isInMaintenanceENV = process.env.REACT_APP_IS_IN_MAINTENANCE;
const isInMaintenance = isInMaintenanceENV === 'TRUE';

const InitMixpanel = () => {
  useInitMixpanel();

  return null;
};

const hjid = +(process.env.REACT_APP_HOTJAR_ID ?? '');
const hjsv = +(process.env.REACT_APP_HOTJAR_VERSION ?? '');

class Routes extends React.Component {
  constructor(props) {
    super(props);

    this.widgetInstance;
    this.widget = widget;
  }

  componentDidMount() {
    const { user } = this.props;
    // const email = _.get(user, 'email', '');

    (hjid && hjsv) && hotjar.initialize(hjid, hjsv);

    // if(email.includes('@contentoo.com') || email.includes('@sapient.pro')) {
    user && (this.widgetInstance = this.widget());
    // }
  }

  componentDidUpdate() {
    const { user } = this.props;
    user
      ? (this.widgetInstance = this.widget())
      : this.widgetInstance && this.widgetInstance.then((data) => data.hide());

    /* if (prevProps.user !== this.props.user) {
     let { user } = this.props;
     const email = _.get(user, 'email', '');
     email.includes('@contentoo.com') || email.includes('@sapient.pro')
     ? (this.widgetInstance = this.widget())
     : this.widgetInstance
     ? this.widgetInstance.then((data) => data.hide())
     : null;
     } */
  }

  render() {
    const {
      user,
      isAuthenticated,
      isImpersonated,
    } = this.props;
    const role = _.get(user, 'type', '');
    const state = _.get(user, 'state', '');
    const userId = _.get(user, 'id', '');
    const lang = LANGUAGE_CODES[_.get(user, 'language', 'English')] || navigator.language.toString().slice(0, 2);
    // const redirectTo = (role !== 'WRITER' && role !== 'DESIGNER') ? 'dashboard/projects/my' :
    // 'dashboard/projects/active';
    let redirectTo = 'dashboard/projects/active';

    if (AVAILABLE_LANGUAGES.indexOf(lang) === -1) {
      const newLang = AVAILABLE_LANGUAGES[0];
      return <Redirect to={`/${newLang}/${redirectTo}`} />;
    }

    if (role === 'WRITER' || role === 'DESIGNER' || role === 'VIDEOEDITOR') {
      if (state === 'ONBOARDING') {
        redirectTo = `lets-go`;
      } else if (state === 'PENDING_APPROVAL') {
        redirectTo = `freelancer-profile/${userId}`;
      }
    } else {
      redirectTo = `dashboard/projects/active`;
    }

    let translations;

    switch (lang) {
      case 'en':
        translations = translationsEn;
        break;
      case 'de':
        translations = translationsDe;
        break;
      default:
        translations = translationsEn;
    }

    const originalUserRole = user?.originalType;
    const isAdmin = [USER_ROLES.ADMIN, USER_ROLES.SUPER].includes(originalUserRole);
    const shouldShowMaintenance = isInMaintenance && (
      isAuthenticated && !isAdmin
    );

    const isFreelancer = isUserFreelancerOrProfessional(role);
    const isVATStatusSelected = Boolean(user?.tipalti_vat_status) && !user?.tipalti_vat_type_id;
    const isUserNotRegistered = !user?.tipalti_id || !user?.tipalti_complete_onboarding_at;
    const shouldShowAutomaticBilling = ((isFreelancer && isVATStatusSelected && !isImpersonated)
      || (isUserNotRegistered && isFreelancer && !isImpersonated)) && (!user?.is_tipalti_onboarding_disabled);

    const shouldShowFinancial = [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER,
      USER_ROLES.WRITER,
      USER_ROLES.DESIGNER,
      USER_ROLES.PROFESSIONAL,
    ].includes(role);

    return (
      <TranslatorProvider translations={translations}>
        <Router history={history}>
          <InitMixpanel />
          <div className={classnames('root-wrapper', { 'german-lan': lang === 'de' })}>
            <LoadingScreen />
            <Switch>
              <PublicRoute
                lazy
                path={`/${lang}/login`}
                component={AuthPage}
                lang={lang}
              />
              {shouldShowMaintenance && (
                <>
                  <PublicRoute
                    lazy
                    path={`/${lang}/maintenance-page`}
                    component={MaintenancePage}
                    lang={lang}
                  />
                  <Redirect to={`/${lang}/maintenance-page`} />
                </>
              )}
              <PublicRoute
                lazy
                path={`/${lang}/reset-password`}
                component={ResetPassword}
                lang={lang}
              />
              <PublicRoute
                lazy
                path={`/${lang}/new-password`}
                component={NewPassword}
                lang={lang}
              />
              <PublicRoute
                lazy
                path={`/${lang}/sign-up`}
                component={SignUpPage}
                lang={lang}
              />

              <PublicRoute
                lazy
                path={`/${lang}/profile-deactivated`}
                component={ProfileDeactivatedPage}
                lang={lang}
              />

              <PublicRoute
                lazy
                path={`/${lang}/verify-email/:token`}
                component={ConfirmEmailPage}
                lang={lang}
              />
              <SignUpRoute
                lazy
                path={`/${lang}/lets-go`}
                component={GetStartedPage}
                lang={lang}
              />
              <SignUpRoute
                lazy
                path={`/${lang}/sign-up-steps`}
                component={RegistrationStepList}
                lang={lang}
              />
              <PrivateRoute
                lazy
                path={`/${lang}/thank-you`}
                component={ConfirmationPage}
                lang={lang}
              />
              <PublicRoute
                lazy
                path={`/${lang}/reset-password-confirmation`}
                component={ResetPasswordConfirmation}
                lang={lang}
              />
              {shouldShowAutomaticBilling && (
              <>
                <PrivateRoute
                  path={`/${lang}/automatic-billing`}
                  component={PaymentDetailsPage}
                  lang={lang}
                  lazy
                />
                <Redirect to={`/${lang}/automatic-billing`} />
              </>
              )}
              <PrivateRoute
                path={`/${lang}/dashboard`}
                component={Dashboard}
                lang={lang}
                lazy
              />
              {
                 (USER_ROLES.CLIENT === role) && (
                 <PrivateRoute
                   path={`/${lang}/new-project`}
                   component={NewProjectNew}
                   lang={lang}
                   lazy
                 />
                 )
              }
              <PrivateRoute
                path={`/${lang}/project/:id`}
                component={ProjectDetails}
                lang={lang}
                lazy
              />
              <PrivateRoute
                lazy
                path={`/${lang}/evaluation-project/:id`}
                component={EvaluationProjectDetails}
                lang={lang}
              />
              <PrivateRoute
                path={`/${lang}/one-off-project/:id`}
                component={OneOffProjectDetails}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/design/:id`}
                component={DesignDetails}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/plan-details/:id`}
                component={DefaultRatePlanDetails}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/writer-profile/:id`}
                component={UserProfile}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/freelancer-profile/:id`}
                component={FreelancerProfile}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/designer-profile/:id`}
                component={UserProfile}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/client-profile/:id`}
                component={UserProfile}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/admin-profile/:id`}
                component={UserProfile}
                lang={lang}
                lazy
              />
              <PrivateRoute
                lazy
                path={`/${lang}/add-client`}
                component={AddUser}
                lang={lang}
              />
              <PrivateRoute
                path={`/${lang}/add-writer`}
                component={AddUser}
                lang={lang}
                lazy
              />
              <PrivateRoute
                lazy
                path={`/${lang}/add-designer`}
                component={AddUser}
                lang={lang}
              />
              <PrivateRoute
                lazy
                path={`/${lang}/add-admin`}
                component={AddUser}
                lang={lang}
              />
              <PrivateRoute
                lazy
                path={`/${lang}/add-professional`}
                component={AddUser}
                lang={lang}
              />
              <PrivateRoute
                path={`/${lang}/settings`}
                component={UserSettings}
                componentProps={{
                  role,
                }}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/add-writer-to-team`}
                component={AddCopywriterToTeam}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/add-designer-to-team`}
                component={AddDesignerToTeam}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/new-brainstorm`}
                component={NewBrainstorm}
                lang={lang}
                lazy
              />
              <PrivateRoute
                path={`/${lang}/brainstorm/:id`}
                component={BrainstormDetails}
                lang={lang}
                lazy
              />
              {
                shouldShowFinancial && (
                <PrivateRoute
                  path={`/${lang}/freelancer-financial/:id`}
                  component={AccountPage}
                  lang={lang}
                  componentProps={{
                    role,
                  }}
                  lazy
                />
                )
              }
              <Redirect to={`/${lang}/${redirectTo}`} />
            </Switch>
          </div>
        </Router>
      </TranslatorProvider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  isImpersonated: state.auth.isImpersonated,
});

export default connect(mapStateToProps)(Routes);

// dont forget about routes inside Details think about containers component.
