import { USER_ROLES } from '../constants';

export const isUserAdmin = (role: string) => (
  [USER_ROLES.ADMIN, USER_ROLES.SUPER].includes(role)
);

export const isUserClient = (role: string) => (
  [USER_ROLES.CLIENT].includes(role)
);

export const isUserFreelancer = (role: string) => (
  [USER_ROLES.WRITER, USER_ROLES.DESIGNER].includes(role)
);

export const isUserProfessional = (role: string) => (
  [USER_ROLES.PROFESSIONAL].includes(role)
);

export const isUserFreelancerOrProfessional = (role: string) => (
  isUserFreelancer(role) || isUserProfessional(role)
);

export const isUserWriterOrProfessional = (role: string) => (
  [USER_ROLES.WRITER].includes(role) || isUserProfessional(role)
);
