import moment from 'moment';
import { PROJECT_STATUS } from '../constants';
import { formatNumber } from '../../components/core/components/FormattedInput';

// TODO: Replace static data with data from Api
export const OTHER_TYPE_ID = 99;
export const DESIGN_OTHER_SERVICE_ID = 11;
export const OTHER_TOOL_ID = 18; // this sets output type
export const GOOGLE_PRESENTATION_OUTPUT_ID = 6;
export const FIGMA_OUTPUT_ID = 19;

const textByStatus = {
  CLIENT: {
    PENDING_ACCEPT: 'ill_accept_the_assignment_within_4_hours',
    DELIVER_DRAFT: 'im_working_on_your_first_draft_please_have_some_patience',
    WAITING_FOR_FEEDBACK: 'please_deliver_your_feedback',
    COMPLETED: 'it_was_pleasure_to_work_with_you_im_looking_forward_to_working_with_you_again',
    SUBMIT_OUTLINES: 'putting_my_thinking_head_on_please_have_some_patience',
    APPROVED: 'hope_you_liked_my_creative_input_looking_forward_to_the_next_brainstorm',
    STARTED: 'putting_my_thinking_head_on_please_have_some_patience',
    FINISHED: 'finished',
  },
  OTHER: {
    PENDING_ACCEPT: 'please_accept_my_assignment',
    DELIVER_DRAFT: 'im_looking_forward_to_receiving_your_draft',
    WAITING_FOR_FEEDBACK: 'im_reviewing_your_draft_please_have_some_patience',
    COMPLETED: 'thank_you_for_your_creativity',
    SUBMIT_OUTLINES: 'looking_for_some_creativity_here_can_you_help_me',
    APPROVED: 'thanks_for_your_good_work',
    STARTED: 'looking_for_some_creativity_here_can_you_help_me',
    FINISHED: 'finished',
  },
};

const textByStatusOfDesignProject = {
  CLIENT: {
    PENDING_ACCEPT: 'ill_accept_the_assignment_within_4_hours',
    DELIVER_DRAFT: 'im_working_on_your_%number%_design_please_have_some_patience',
    WAITING_FOR_FEEDBACK: 'please_deliver_your_feedback',
    COMPLETED: 'it_was_pleasure_to_work_with_you_im_looking_forward_to_working_with_you_again',
    SUBMIT_OUTLINES: 'putting_my_thinking_head_on_please_have_some_patience',
    APPROVED: 'hope_you_liked_my_creative_input_looking_forward_to_the_next_brainstorm',
    STARTED: 'putting_my_thinking_head_on_please_have_some_patience',
    FINISHED: 'finished',
  },
  OTHER: {
    PENDING_ACCEPT: 'please_accept_my_assignment',
    DELIVER_DRAFT: 'im_looking_forward_to_receiving_your_design',
    WAITING_FOR_FEEDBACK: 'im_reviewing_your_design_please_have_some_patience',
    COMPLETED: 'thank_you_for_your_creativity',
    SUBMIT_OUTLINES: 'looking_for_some_creativity_here_can_you_help_me',
    APPROVED: 'thanks_for_your_good_work',
    STARTED: 'looking_for_some_creativity_here_can_you_help_me',
    FINISHED: 'finished',
  },
};

const digitsToWords = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
  8: 'eighth',
  9: 'ninth',
  10: 'tenth',
};

function getTextByStatus(customer, status, draftsCount = 0) {
  const role = customer !== 'CLIENT' ? 'OTHER' : 'CLIENT';

  if (draftsCount) {
    return textByStatus[role][status].replace('first', 'new');
  }

  return textByStatus[role][status];
}

function getTextByStatusOnDesignProject(customer, status, draftsCount = 0) {
  const role = customer !== 'CLIENT' ? 'OTHER' : 'CLIENT';

  if (draftsCount) {
    return textByStatusOfDesignProject[role][status].replace('%number%', digitsToWords[draftsCount] || 'last');
  }

  return textByStatusOfDesignProject[role][status].replace('%number%', 'first');
}

function extraFeeForUrgency(firstDraft, startDate = new Date()) {
  const now = moment(startDate);
  const diff = moment(firstDraft).diff(now, 'days');

  if (Number.isNaN(diff) || diff > 7 || diff < 0) {
    return false;
  }

  const arrayDays = [];

  for (let i = 0; i < diff; i += 1) {
    arrayDays.push(now.add(1, 'days').clone());
  }

  const excludeDate = {
    1.1: true,
    27.4: true,
    25.12: true,
    26.12: true,
  };

  return arrayDays.filter(date => date.day() !== 0 && date.day() !== 6 && !excludeDate[date.format('D.M')]).length < 2;
}

function removeMask(value) {
  return value.toString().split('.').join('');
}

function getWordFromDigit(digit) {
  return digitsToWords[digit] || 'final';
}

function formatTextCurrency(value) {
  return value.toString().replace(/,00/g, ',-');
}

function removeZerosFromPercent(value) {
  return value.toString().replace(/,00/g, '');
}

function formatDecimalTextCurrency(value) {
  const valueLocale = Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  });
  return valueLocale.format(value);
}

const formatDecimalTextCurrencyNoMinDigits = (value) => {
  const valueLocale = Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 3,
  });
  return valueLocale.format(value);
};

/**
 * returns formatted number without decimals in `1.234` format
 * @param {number} number
 * @returns {string}
 */
const formatPriceWithoutDecimals = (number) => formatNumber({
  number,
  locale: 'de-DE',
  options: { maximumFractionDigits: 0 },
});

const priceFormat = (price) => {
  const formattedPrice = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(price);
  return `€ ${formattedPrice}`;
};

const priceFormatNoMinDigits = (price) => {
  const formattedPrice = new Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
  }).format(price);
  return `€ ${formattedPrice}`;
};

const getDefaultDatePeriodFormat = (options = {}) => {
  const {
    format = 'YYYY-MM',
  } = options;

  const now = moment();
  const startTime = moment().subtract(1, 'years');

  return {
    endDate: now.format(format),
    startDate: startTime.format(format),
  };
};

const formatCurrencyValueLabel = (value) => {
  const valueLocale = Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  });
  return valueLocale.format(value).replace(/,00/g, '');
};

const getProjectStatus = (status) => {
  if (status === PROJECT_STATUS.FINISHED) return 'Finished';
  else if (status === PROJECT_STATUS.COMPLETED) return 'Completed';
  else return 'Active';
};

/**
 * @param filters
 * @returns {string}
 */
const getQueryParamsFromFilters = (filters) => (
  Object.entries(filters)
    .reduce((accumulator, [key, values]) => {
      const prefix = accumulator ? `${accumulator}&` : '';
      return (
        typeof values === 'number' || values.length
          ? Array.isArray(values)
            ? `${prefix}${key}=${values.join(',')}`
            : `${prefix}${key}=${values}`
          : accumulator
      );
    }, '')
);

const getQueryParams = (filters, perPage) => {
  const queryParams = getQueryParamsFromFilters(filters);

  return `${queryParams}&resultsPerPage=${perPage}`;
};

export {
  getTextByStatus,
  getTextByStatusOnDesignProject,
  extraFeeForUrgency,
  getWordFromDigit,
  removeMask,
  formatTextCurrency,
  removeZerosFromPercent,
  getProjectStatus,
  formatDecimalTextCurrency,
  formatDecimalTextCurrencyNoMinDigits,
  formatPriceWithoutDecimals,
  getDefaultDatePeriodFormat,
  formatCurrencyValueLabel,
  priceFormat,
  priceFormatNoMinDigits,
  getQueryParamsFromFilters,
  getQueryParams,
};
