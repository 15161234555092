export enum BackendConstantsKeys {
  PROJECT_VALID_FILE_EXTENSIONS = 'PROJECT_VALID_FILE_EXTENSIONS',
  PROJECT_VALID_CROWDIN_FILE_EXTENSIONS = 'PROJECT_VALID_CROWDIN_FILE_EXTENSIONS',
  DESIGN_TOOL_TYPES = 'DESIGN_TOOL_TYPES',
  FREELANCER_DEACTIVATION_REASONS = 'FREELANCER_DEACTIVATION_REASONS',
  NOTE_TYPES = 'NOTE_TYPES',
  LABS_ALLOW_LANGUAGE_IDS = 'LABS_ALLOW_LANGUAGE_IDS',
  LABS_ALLOW_FILE_EXTENSIONS = 'LABS_ALLOW_FILE_EXTENSIONS',
  LABS_ALLOW_FILE_MAX_SIZES = 'LABS_ALLOW_FILE_MAX_SIZES',
}

export enum ToolNameEnum {
  DOWNLOADABLE_DESIGN = 'DOWNLOADABLE_DESIGN',
  FILES_FLOW = 'FILES_FLOW',
  GOOGLE_PRESENTATION = 'GOOGLE_PRESENTATION',
}
