import * as types from '../constants';
import { IUser, OrNull } from '../types';
import { SimpleUserType, UserRoles } from '../utils';

const impersonateId = localStorage.getItem('impersonate_id');

export type ImpersonateData = SimpleUserType & {
  type: UserRoles,
  client_id: OrNull<number>,
};

type AuthType = {
  // check this field
  isAuthenticated: boolean,
  user: OrNull<IUser>,
  loginErrorMessage: OrNull<string>,
  resetPasswordErrorMessage: OrNull<string>,
  newPasswordErrorMessage: OrNull<string>,
  isImpersonated: boolean,
  showModal: boolean,
  impersonatedData: OrNull<ImpersonateData>,
};

const initialState: AuthType = {
  isAuthenticated: false,
  user: null,
  loginErrorMessage: null,
  resetPasswordErrorMessage: null,
  newPasswordErrorMessage: null,
  isImpersonated: !!impersonateId,
  showModal: false,
  impersonatedData: null,
};

const resetAuthData: {
  isImpersonated: boolean;
  isAuthenticated: boolean;
  user: null;
} = {
  isAuthenticated: false,
  user: null,
  isImpersonated: false,
};

export default function auth(state = initialState, action): AuthType {
  switch (action.type) {
    case types.UPDATE_USER_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload,
        },
      };
    case types.UPDATE_TIPALTI_ID:
      return {
        ...state,
        user: {
          ...state.user,
          tipalti_id: action.payload,
        },
      };
    case types.UPDATE_TIPALTI_COMPLETE_ONBOARDING_AT:
      return {
        ...state,
        user: {
          ...state.user,
          tipalti_complete_onboarding_at: action.payload,
        },
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        ...resetAuthData,
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        ...resetAuthData,
        loginErrorMessage: action.payload,
      };
    case types.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case types.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        ...resetAuthData,
      };
    case types.IMPERSONATE:
      return {
        ...state,
        isImpersonated: true,
        user: {
          ...state.user,
          type: action.payload,
        },
      };
    case types.UNIMPERSONATE:
      return {
        ...state,
        isImpersonated: false,
        user: {
          ...state.user,
          // restore original user role
          type: state.user.originalType,
        },
        impersonatedData: null,
      };
    case types.SET_IMPERSONATED_DATA:
      return {
        ...state,
        isImpersonated: true,
        impersonatedData: action.payload,
        user: {
          ...state.user,
          type: action.payload.type,
        },
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordErrorMessage: '',
        showModal: true,
      };
    case types.CLOSE_POPUP:
      return {
        ...state,
        showModal: false,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordErrorMessage: action.payload.message,
      };
    case types.REQUEST_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordErrorMessage: '',
      };
    case types.REQUEST_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        newPasswordErrorMessage: action.payload.message,
      };
    case types.RECEIVE_PROFILE_SUCCESS:
      return {
        ...state,
        // set `isAuthenticated` to true, as we've get profile
        isAuthenticated: true,
        user: {
          ...action.payload.user,
          // save original user role
          originalType: action.payload.user.type,
        },
      };
    case types.UPDATE_USER_STATE:
      return {
        ...state,
        user: {
          ...state.user,
          state: action.payload,
        },
      };
    default:
      return state;
  }
}
