import { FilterElement, SelectedFilterValueType, SelectedMultipleFilters } from '../types';
import { isEmpty } from './utils';
import { DropdownType } from '../../types';

export const DEFAULT_PER_PAGE: number = 10;

// multi || isArrayValue ? SelectedFilterValueType : FilterElement['id'];
interface GetNewFilterValue {
  <IsMulti extends boolean, IsArrayValue extends boolean>(
    params: {
      value: FilterElement['id'],
      multi?: IsMulti,
      isArrayValue?: IsArrayValue,
      filterValue: SelectedFilterValueType,
    }): (
    IsMulti extends true
      ? SelectedFilterValueType
      : IsArrayValue extends true
        ? SelectedFilterValueType
        : FilterElement['id']
  );
  (params: {
    value: FilterElement['id'],
    multi?: boolean,
    isArrayValue?: boolean,
    filterValue: SelectedFilterValueType,
  }): FilterElement['id']
}

export const getNewFilterValue: GetNewFilterValue = ({
  value,
  multi = false,
  isArrayValue = true,
  filterValue,
}) => {
  // change logic to remove/add if multi and return array value if isArray
  if (multi) {
    const isInArray = filterValue.includes(value);
    return isInArray
      ? filterValue.filter(item => item !== value)
      : [...filterValue, value];
  } else {
    return isArrayValue ? [value] : value;
  }
};

export const appendDefaultFilters = <T extends Object>(filters: T, defaultFilters: Partial<T>): T => {
  const filtersToBeAdded: Partial<T> = {};
  Object.entries(defaultFilters)
    .forEach(([key, value]) => {
      const isKeyExist: boolean = Object.hasOwn(filters, key);
      const isValueEmpty: boolean = isEmpty(filters[key]);
      const shouldAddValue: boolean = !isKeyExist || isValueEmpty;
      shouldAddValue && (filtersToBeAdded[key] = value);
    });

  return { ...filters, ...filtersToBeAdded };
};

export const formatFiltersValue = <T extends SelectedMultipleFilters>(filters: T): SelectedMultipleFilters => (
  Object.fromEntries(
    Object.entries(filters)
      // remove empty arrays and strings from filters
      .filter(([, value]) => {
        const isString = typeof value === 'string';
        const isArray = Array.isArray(value);
        return !(isString || isArray) || value.length;
      })
      .map(([key, value]) => [
        key,
        Array.isArray(value)
          ? value.join(',')
          : value,
      ]),
  )
);

export type FilterItemType = DropdownType & { checked: boolean };

export type FiltersStateItemType = { [id: number]: FilterItemType };

export type FiltersStateType<T extends string = string> = {
  [filterKey in T]: FiltersStateItemType
};

export type FilterLabelType<T extends string = string> = DropdownType & { filterKey: T };

export type FilterLabelsStateType<F extends string = string, K extends string = string> = { [key in K]: FilterLabelType<F> };

export const getFilterStateItems = (items: DropdownType[]): FiltersStateItemType => (
  items.reduce((filterStateItems, { id, name }) => ({
    ...filterStateItems,
    [id]: { id, name, checked: false },
  }), {})
);
