import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styles from './PaymentDetailsHeader.module.scss';
import ContentooLogoSVG from '../../../assets/svg/contentoo-logo-black.svg';
import { useAppTranslate } from '../../../hooks';
import { client } from '../../../utils';
import { getFreelancerData } from '../../../actions/new-freelancer';
import * as types from '../../../constants';
import CrossIconSVG from '../../../assets/svg/cross.svg';
import { logout } from '../../../actions/auth';
import { onCancelUpdating, onStartUpdating } from '../../../actions/core';
import ChooseVatTypeModal from '../ChooseVatTypeModal';
import ChooseVatTypeSuccessModal from '../ChoosevatTypeSuccessModal';

const PaymentDetailsHeader: FunctionComponent<{ profileId: number }> = ({ profileId }) => {
  const t = useAppTranslate();
  const dispatch = useDispatch();
  const [showChooseVatTypeModal, setShowChooseVatTypeModal] = useState<boolean>(false);
  const [selectedVatType, setSelectedVatType] = useState<number | null>(null);
  const [isSuccessfulChoiceVAT, setIsSuccessfulChoiceVAT] = useState<boolean>(false);
  const history = useHistory();
  const { pathname } = window.location;
  const lang = pathname.split('/')[1];

  const updateCurrentFreelancer = (): void => {
    dispatch(getFreelancerData(profileId)).then(({ user }) => {
      dispatch({
        type: types.UPDATE_TIPALTI_COMPLETE_ONBOARDING_AT,
        payload: user.tipalti_complete_onboarding_at,
      });
    });
  };

  const checkIsPayeePayable = (): void => {
    dispatch(onStartUpdating());
    client.get('/tipalti/get-payee')
      .then(({ data: { result: { properties, tipalti_vat_status: tipaltiVatStatus, tipalti_vat_type_id: tipaltiVatTypeId } } }) => {
        if ((properties.Payable === 'True' && Boolean(!tipaltiVatStatus))
          || (properties.Payable === 'True' && Boolean(tipaltiVatStatus) && Boolean(tipaltiVatTypeId))) {
          updateCurrentFreelancer();
          history.push(`/${lang}/dashboard/projects`);
        } else if (properties.Payable === 'True' && Boolean(tipaltiVatStatus) && !tipaltiVatTypeId) {
          setShowChooseVatTypeModal(true);
        } else {
          dispatch(logout());
        }
      }).finally(() => dispatch(onCancelUpdating()));
  };

  const onCloseClick = (): void => {
    checkIsPayeePayable();
  };

  const onSaveVatType = () => {
    if (selectedVatType) {
      dispatch(onStartUpdating());
      const formData = new FormData();
      formData.append('tipalti_vat_type_id', selectedVatType.toString());
      client.post('/tipalti/store-vat-type-id', formData).then(() => {
        setShowChooseVatTypeModal(false);
        setIsSuccessfulChoiceVAT(true);
      }).finally(dispatch(onCancelUpdating()));
    }
  };

  return (
    <header className={styles.paymentDetailsHeader}>
      <div className={styles.title}>
        <ContentooLogoSVG className={styles.icon} />
        <h1 className={styles.titleText}>{t('Payment Details')}</h1>
      </div>

      <button
        className={styles.closeButton}
        type='button'
        onClick={onCloseClick}
      >
        {t('close')}
        <CrossIconSVG className={styles.icon} />
      </button>

      {
        isSuccessfulChoiceVAT && (
          <ChooseVatTypeSuccessModal
            setIsSuccessfulChoiceVAT={setIsSuccessfulChoiceVAT}
            updateCurrentFreelancer={updateCurrentFreelancer}
          />
        )
      }

      {
        showChooseVatTypeModal && (
          <ChooseVatTypeModal
            setShowChooseVatTypeModal={setShowChooseVatTypeModal}
            setSelectedVatType={setSelectedVatType}
            selectedVatType={selectedVatType}
            onSaveVatType={onSaveVatType}
          />
        )
      }
    </header>
  );
};

export default PaymentDetailsHeader;
