import React, { Dispatch, FunctionComponent } from 'react';
import { Label } from 'reactstrap';
import classnames from 'classnames';
import styles from '../PaymentDetailsHeader/PaymentDetailsHeader.module.scss';
import CrossIconSVG from '../../../assets/svg/cross.svg';
import RadioButton from '../../core/components/RadioButton';
import InfoBlock from '../../core/components/InfoBlock';
import { POSITIONS } from '../../../utils';
import Modal from '../../core/FinancialsOwerviewComponents/Modal';
import { useAppTranslate } from '../../../hooks';

const TIPALTI_NETHERLANDS_VAT_TYPE_ID = 1;
const TIPALTI_SHIFTED_VAT_TYPE_ID = 2;
const TIPALTI_NO_EU_VAT_TYPE_ID = 3;

const getVatTypesArray = (t): { id: string, value: number, label: string, tooltipText: string }[] => [
  {
    id: 'TIPALTI_NETHERLANDS_VAT_TYPE_ID',
    value: TIPALTI_NETHERLANDS_VAT_TYPE_ID,
    label: t('21% VAT (BTW) The Netherlands'),
    tooltipText: t('For Dutch freelancers that are subject to VAT'),
  },
  {
    id: 'TIPALTI_SHIFTED_VAT_TYPE_ID',
    value: TIPALTI_SHIFTED_VAT_TYPE_ID,
    label: t('VAT reverse charge applied (EU)'),
    tooltipText: t('For freelancers from other EU-countries (not NL)'),
  },
  {
    id: 'TIPALTI_NO_EU_VAT_TYPE_ID',
    value: TIPALTI_NO_EU_VAT_TYPE_ID,
    label: t('No VAT applicable (non-EU)'),
    tooltipText: t('For non-EU freelancers'),
  },
];

interface IChooseVatTypeModal {
  setShowChooseVatTypeModal: Dispatch<boolean>,
  setSelectedVatType: Dispatch<number | null>,
  selectedVatType: number | null,
  onSaveVatType: VoidFunction,
}

const ChooseVatTypeModal: FunctionComponent<IChooseVatTypeModal> = ({
  setShowChooseVatTypeModal,
  setSelectedVatType,
  selectedVatType,
  onSaveVatType,
}) => {
  const t = useAppTranslate();
  const vatTypesArray = getVatTypesArray(t);

  return (
    <Modal
      onBackdropClick={() => setShowChooseVatTypeModal(false)}
      withoutCloseButton
      classNameModal={styles.vatModal}
      className={styles.content}
    >
      <header className={styles.vatModalHeader}>
        <button
          type='button'
          className={styles.closeButton}
          onClick={() => setShowChooseVatTypeModal(false)}
        >
          <CrossIconSVG />
        </button>
        <h1 className={styles.title}>{t('Choose your VAT type')}</h1>
        <p className={styles.subText}>
          {t([
            'You indicated that you are registered for VAT (i.e. that you are not exempt for VAT).',
            'Choose here which VAT scheme should be applied to your invoices.',
          ].join(' '))}
        </p>
      </header>

      <h3 className={styles.listTitle}>{t('What is your VAT type?')}</h3>

      <ul className={styles.list}>
        {
          vatTypesArray.map(type => (
            <li
              key={type.id}
              className={styles.listItem}
            >
              <RadioButton
                name={type.id}
                onChange={() => setSelectedVatType(type.value)}
                checked={selectedVatType === type.value}
              />
              <Label
                for='name'
                className={styles.planTypeItemLabel}
              >
                {type.label}
              </Label>
              <InfoBlock
                position={POSITIONS.TOP}
                tooltipContent={type.tooltipText}
                messageClassName={styles.tooltipMessage}
              >
                <span className={styles.infoIcon}>?</span>
              </InfoBlock>
            </li>
          ))
        }
      </ul>
      <button
        type='button'
        disabled={!selectedVatType}
        onClick={onSaveVatType}
        className={classnames(styles.saveButton, { [styles.saveButton_disabled]: !selectedVatType })}
      >
        {t('save')}
      </button>
    </Modal>
  );
};

export default ChooseVatTypeModal;
