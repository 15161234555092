import * as types from '../constants';

const initialState = [];

export default function analyzes(state = initialState, action) {
  switch (action.type) {
    case types.GET_ANALYZES:
      return action.payload;
    default:
      return state;
  }
}
