import React, { Dispatch, FunctionComponent } from 'react';
import styles from './ChoosevatTypeSuccessModal.module.scss';
import CrossIconSVG from '../../../assets/svg/cross.svg';
import CheckIconSVG from '../../../assets/svg/check-icon.svg';
import Modal from '../../core/FinancialsOwerviewComponents/Modal';
import { useAppTranslate } from '../../../hooks';

interface IChooseVatTypeSuccessModal {
  setIsSuccessfulChoiceVAT: Dispatch<boolean>,
  updateCurrentFreelancer: VoidFunction
}

const ChooseVatTypeSuccessModal: FunctionComponent<IChooseVatTypeSuccessModal> = ({
  setIsSuccessfulChoiceVAT,
  updateCurrentFreelancer,
}) => {
  const t = useAppTranslate();

  return (
    <Modal
      onBackdropClick={() => setIsSuccessfulChoiceVAT(false)}
      withoutCloseButton
      classNameModal={styles.vatModal}
      className={styles.content}
    >
      <header className={styles.vatModalHeader}>
        <button
          type='button'
          className={styles.closeButton}
          onClick={() => setIsSuccessfulChoiceVAT(false)}
        >
          <CrossIconSVG />
        </button>
        <div className={styles.checkIcon}>
          <CheckIconSVG />
        </div>
        <h1 className={styles.successTitle}>{t('Your VAT type has been saved')}</h1>
        <button
          className={styles.successCloseButton}
          type='button'
          onClick={() => updateCurrentFreelancer()}
        >
          {t('close')}
        </button>
      </header>
    </Modal>
  );
};

export default ChooseVatTypeSuccessModal;
