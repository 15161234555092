const USER_ROLES = {
  CLIENT: 'CLIENT',
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
  WRITER: 'WRITER',
  DESIGNER: 'DESIGNER',
  PROFESSIONAL: 'PROFESSIONAL',
};

export {
  USER_ROLES,
};
