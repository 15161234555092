import history from '../../helpers/history';
import { featureFlagValue } from '../constants';
import { LocalStorageKeys } from '../constants/localStorageKeys';

const redirectToFinancial = (id) => {
  const { pathname } = window.location;
  const financialPathRegExp = /(en|de)\/freelancer-profile\/(\d+)\/financial/;
  const isFinancial = financialPathRegExp.test(pathname);

  const lang = pathname.split('/')[1]; // first is empty string
  const pathToRedirect = `/${lang}/freelancer-profile/${id}/financial/`;

  !isFinancial && window.location.replace(pathToRedirect);
};

const redirectToDeactivatedProfilePage = (lang, deactivatedUser) => {
  localStorage.setItem(LocalStorageKeys.SHOULD_SHOW_DEACTIVATED_PAGE, featureFlagValue);
  history.replace(`/${lang}/profile-deactivated`, { deactivatedUser });
};

export {
  redirectToFinancial,
  redirectToDeactivatedProfilePage,
};
