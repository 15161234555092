import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CloseIconSVG from '../../../../assets/svg/cross-out.svg';
import { hideUpdateBar as hideUpdateBarAction } from '../../../../actions/auth';

const PlatformUpdateBar = ({ platformUpdate, hideUpdateBar }) => {
  const formatedLink = (link) => {
    if (!link.includes('http')) {
      return `https://${link}`;
    } else {
      return link;
    }
  };
  return (platformUpdate.isVisible
    ? (
      <div className='platform-update-container'>
        <div className='title-container'>
          <div className='icon'>i</div>
          <div className='title'>
            {platformUpdate.text}
            .&nbsp;
            {(platformUpdate.link && platformUpdate.link !== 'null') ? (
              <a
                className='read-more-link'
                target='_blank'
                href={formatedLink(platformUpdate.link)}
                rel='noreferrer'
              >
                Read more
              </a>
            ) : ''}
          </div>
        </div>
        <CloseIconSVG
          className='close-icon'
          onClick={hideUpdateBar}
        />
      </div>
    )
    : null);
};

const mapStateToProps = state => ({
  platformUpdate: state.core.platformUpdate,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  hideUpdateBar: hideUpdateBarAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlatformUpdateBar);
