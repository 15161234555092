import Color from 'color';
import BackgroundColorTheif from '../../libs/background-color-theif';

const getImageBgInfo = (ref) => {
  const backgroundColorThief = new BackgroundColorTheif();
  console.info(backgroundColorThief);
  const imageBgColor = backgroundColorThief.getBackGroundColor(ref);
  console.info(imageBgColor);
  const colorObj = Color.rgb(imageBgColor);
  const isLight = colorObj.isLight();
  return {
    color: imageBgColor,
    colorObj,
    isLight,
  };
};

export {
  getImageBgInfo,
};
