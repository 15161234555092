import _ from 'lodash';
import * as types from '../constants';
import { GET_CLIENT_PROFILE_SUCCESS } from '../constants/user-profile';

const initialState = {
  brainstorm: {},
  activeFeedbackCard: {},
  messages: [],
  unreadMessages: [],
  clientProfile: {},
};

export default function brainstormDetails(state = initialState, action) {
  switch (action.type) {
    case types.GET_BRAINSTORM_BY_ID_SUCCESS:
      return {
        ...state,
        brainstorm: action.payload,
        unreadMessages: _.get(action.payload, 'workelement.users', []).map(user => user.unread_count),
      };
    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          workelement: {
            ...state.brainstorm.workelement,
            users: state.brainstorm.workelement.users.map(item => {
              if (item.id === action.payload.id) {
                return {
                  ...item,
                  submitdrafts: [
                    ...item.submitdrafts,
                    action.payload.file,
                  ],
                };
              }
              return item;
            }),
          },
        },
      };
    case types.ADD_BRAINSTORM_FILE_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          files: [
            ...state.brainstorm.files,
            action.payload,
          ],
        },
      };
    case types.REMOVE_RAINSTORM_FILE_SUCCESS: {
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          files: state.brainstorm.files.filter(item => item.id !== action.payload.id),
        },
      };
    }
    case types.ADD_PARTICIPANT_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          users: [
            ...state.brainstorm.users,
            action.payload,
          ],
        },
      };
    case types.REMOVE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          users: state.brainstorm.users.filter(item => item.id !== action.payload.id),
        },
      };
    case types.DELETE_FILE_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          workelement: {
            ...state.brainstorm.workelement,
            users: state.brainstorm.workelement.users.map(item => {
              if (item.id === action.payload.userId) {
                return {
                  ...item,
                  submitdrafts: item.submitdrafts.filter(file => file.id !== action.payload.fileId),
                };
              }
              return item;
            }),
          },
        },
      };
    case types.DELIVER_FEEDBACK:
      return {
        ...state,
        activeFeedbackCard: {
          ...state.activeFeedbackCard,
          [action.payload]: true,
        },
      };
    case types.DISABLE_ACTIVE_FEEDBACKCARD:
      return {
        ...state,
        activeFeedbackCard: {},
      };
    case types.UPLOAD_BRAINSTORM_FEEDBACK_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          workelement: {
            ...state.brainstorm.workelement,
            users: state.brainstorm.workelement.users.map(item => {
              if (item.id === action.payload.userId) {
                const draft = item.submitdrafts.find(({ id }) => id === action.payload.draftId);
                const files = [
                  ...draft.files,
                  action.payload.feedback,
                ];

                return {
                  ...item,
                  submitdrafts: [
                    ...item.submitdrafts.filter(({ id }) => id !== action.payload.draftId),
                    {
                      ...draft,
                      files,
                    },
                  ],
                };
              }
              return item;
            }),
          },
        },
      };
    case types.DELETE_BRAINSTORM_FEEDBACK_SUCCESS:
      return {
        ...state,
        brainstorm: {
          ...state.brainstorm,
          workelement: {
            ...state.brainstorm.workelement,
            users: state.brainstorm.workelement.users.map(item => {
              if (item.id === action.payload.userId) {
                const draft = item.submitdrafts.find(({ id }) => id === action.payload.draftId);
                const files = draft.files.filter(({ id }) => id !== action.payload.fileId);

                return {
                  ...item,
                  submitdrafts: [
                    ...item.submitdrafts.filter(({ id }) => id !== action.payload.draftId),
                    {
                      ...draft,
                      files,
                    },
                  ],
                };
              }
              return item;
            }),
          },
        },
      };
    case types.GET_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      };
    case types.MARK_AS_READ:
      return {
        ...state,
        unreadMessages: [],
      };
    case types.MARK_MESSAGE_AS_DELETED:
      return {
        ...state,
        messages: state.messages.map((message) => (
          message.id === action.payload.id
            ? { ...message, deleted: action.payload.deleted }
            : message
        )),
      };
    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [
          ...state.messages,
          action.payload,
        ],
      };
    case types.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...action.payload],
      };
    case GET_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        clientProfile: action.payload,
      };
    default:
      return state;
  }
}
