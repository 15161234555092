import * as types from '../constants';

const initialState = [];

export function projectTypes(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECT_TYPE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

const initialStateProjectTypeById = {};

export function projectTypeById(state = initialStateProjectTypeById, action) {
  switch (action.type) {
    case types.GET_PROJECT_TYPE_SUCCESS:
      return action.payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    default:
      return state;
  }
}

export default projectTypes;
